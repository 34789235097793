import React, {Component} from "react";
import './Styles/LampaFeed.css';
import LaraItem from "./LaraItem";
import ReactTwitchEmbedVideo from "react-twitch-embed-video"
import GamesList from "./GamesList";
import ShowButton from "../Buttons/ShowButton";


class LampaFeed extends Component<any, any>{

	constructor() {
		super("");
		this.state = {
			downloaded:false,
			result:[]
		}
	}


	lara = <GamesList dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_LARA_GET as string}/>
	rezik = <GamesList dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_RESIDENT_GET as string}/>

	render() {


		return (<div>
			<div className="StickyTop">
				<a id="topLink" href="https://lampafeed.ru/"><img className="Logo-holder"
																	  src="./favicon.ico" alt="logo"/></a>
				<hr className="Hr"></hr>
				{/*<Wishlist/>*/}
			</div>
			<header className="App-header">
				<p>LAMPAFEED</p>
				<br/>
				<a href="https://www.twitch.tv/lampafeed">Twitch</a>
				<a href="https://vk.com/budkafeed">Вконтакте</a>
				<a href="https://www.youtube.com/@lampafeed">YouTube</a>
				<a href="https://vkplay.live/lampafeed">VKPlay</a>
				<a href="https://www.donationalerts.com/r/lampafeed">DonationAlerts</a>
				<br/>
			</header>



			<><ReactTwitchEmbedVideo channel="lampafeed" chat="mobile" layout="video" height="300" width="532"/><br/></>
			<br/>
			<p>Сейчас мы проходим</p>

			<ShowButton label="Марафон Tomb Raider" render={<><GamesList dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_LARA_GET as string}></GamesList><br/></>}/>
			<ShowButton label="Марафон Resident Evil" render={<><GamesList dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_RESIDENT_GET as string}/><br/></>}/>



			<br/><br/><br/><br/><br/><br/>
		</div>);
	}


}


export default LampaFeed