import React, {Component} from "react";
import './Styles/LampaFeed.css';
import {TextButton} from "../Buttons/Buttons";


interface LaraItemState {description:boolean, gameData:any}
class LaraItem extends Component<string[], LaraItemState>{

	constructor(info :string[]) {
		super(info);
		this.state = {
			description: false,
			gameData:null,
		}

	}

	getFindLink(gameName: string){
		return (process.env.REACT_APP_RAWR_LINK as string)
			.replace("{0}","")
			.replace("{1}",process.env.REACT_APP_RAWR_KEY as string)
			.replace("{2}","&search=" + gameName)
			.replace("{3}","&search_precise=true")
			.replace("{4}","")
	}

	getGameLink(gameId:string){
		return (process.env.REACT_APP_RAWR_LINK as string)
			.replace("{0}","/"+gameId)
			.replace("{1}",process.env.REACT_APP_RAWR_KEY as string)
			.replace("{2}","")
			.replace("{3}","")
			.replace("{4}","")
	}


	downloadData(){

		fetch(this.getFindLink(this.props[1])).then((res) => {
			res.text().then((text) => {
				let id = JSON.parse(text).results[0].id as string;
				fetch(this.getGameLink(id)).then((res) => {
					res.text().then((gameData)=> {
						this.setState({gameData: JSON.parse(gameData)})
					})
				})
			})

		})
	}

	componentDidMount() {
		this.openDesc = this.openDesc.bind(this);
		this.closeDesc = this.closeDesc.bind(this);
		this.downloadData();
	}

	openDesc(){

		this.setState({description:true})

	}

	closeDesc(){
		this.setState({description:false})
	}

	render() {

		let desc = <></>
		if(!this.state.description)
		{
			desc = <><TextButton label="Открыть описание" function={() => this.openDesc()}/><p></p>
			</>

		} else {
			desc = <>
				<TextButton label="Скрыть описание" function={() => this.closeDesc()}/>
				<p className="laraDesc">{this.props[3]}</p>
			</>;
		}

		let metacriticStyle = (value:number) => {

			if(value < 33)
				return "MetaCriticRed"
			else if (value < 66)
				return "MetaCritic"

			return "MetaCriticGreen"
		}

		let MetaCritic = <>
			{
				(this.state.gameData != null && Number.parseInt(this.state.gameData.metacritic))? (<div className={metacriticStyle(this.state.gameData.metacritic)}>{this.state.gameData.metacritic}</div>):(<></>)
			}
		</>;

		let record = <></>
		if(this.props[6] != "") {
			record = <><a href={this.props[6] as string}>Запись прохождения</a><br/><br/></>;
		}

		return (<div className={"laraItem" + this.props[5]}>
			{/*{<img className="backgroundimage" src={this.state.gameData?.background_image}/>}*/}
			<p>{this.props[1]}</p>
			<p>{this.props[2]}</p>
			<p>Платформа: {this.props[4]}</p>
			{record}
			{desc}
			{MetaCritic}
			<img src={this.props[0]} className="laraImg"></img>

		</div>)
	}
}


export default LaraItem