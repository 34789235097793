import React from 'react';
import './App.css';
import LampaFeed from "./LampaFeed/LampaFeed";

function App() {
  return (
    <div className="App">
        <LampaFeed/>
    </div>
  );
}


interface MyButtonParams {
    label: string
    myValue: () => void;
}

export function MyButton(fn: MyButtonParams) {


    return (
        <button
            className="Glow"
            type="button"
            onClick={(e) => {
                fn.myValue();
            }}
        >{fn.label}</button>
    );
}


export default App;
