import React, {Component} from "react";
import {TextButton} from "./Buttons";
import "./Styles/ShowButton.css"

interface ShowButtonState{
	opened:boolean;
}
interface ShowButtonProps {
	render:JSX.Element
	label:string
}
class ShowButton extends Component<ShowButtonProps, ShowButtonState>{


	constructor(props: ShowButtonProps) {
		super(props);
		this.state = {
			opened:false,
		}
	}

	cachedElement = <></>;

	componentDidMount() {
		this.cachedElement = this.props.render;
	}

	render(){

		let opened = !this.state.opened;
		//let stringOpened = opened? "Открыть ":"Закрыть " ;

		let openedContent = this.cachedElement;

		/*if(!this.state.opened)
			openedContent = <></>*/

		let content = <div className="ShowButtonHolder">
			<TextButton label={this.props.label} function={()=> {this.setState({opened:opened})}}/>
			<br/>
			<br/>
			<div className="FitContent"  style={{display: this.state.opened ? 'inherit' : 'none'}}>{openedContent}</div>
		</div>

		return(content)
	}

}

export default ShowButton