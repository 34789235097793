import React from "react";
import './Styles/TextButton.css';

interface ButtonParams {
	label: string
	function: () => void;
}

export function TextButton(params: ButtonParams){
	return (
		<button
			className="showButton"
			type="button"
			onClick={(e) => {
				params.function();
			}}
		>{params.label}</button>
	);
}